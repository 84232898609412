import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { ImageRounded } from '../../shared/Image'
import Link from '../../shared/Link'
import { Caption, Description, Text, Title } from '../../shared/Typography'
import { ISection } from './types'

interface IProps {
  data: ISection
}

export default function renderSectionomepage({ data }: IProps) {
  const cols = ['100%', '50%']

  return (
    <Container mb={['2em']}>
      <Flex alignItems="center" mb={['0', '4em']} justifyContent="center">
        <Box maxWidth="760px" mb={['1em', '2.5em']}>
          <Title textAlign={['left', 'center']} mb="1rem">
            {data.section.title}
          </Title>
          <Description
            px={['0', '5em']}
            textAlign={['left', 'center']}
            color="greyMedium"
          >
            {data.section.description}
          </Description>
        </Box>
      </Flex>

      <Flex flexWrap="wrap">
        <Box width={cols} mb={['1em', '2.5em']}>
          <ImageRounded path={data.space.images[0]} alt={data.space.title} />
          <Box px={['0', '6em']} mt={['3em', '5em']}>
            <Caption mb=".8em">{data.space.title}</Caption>
            <Text mb="1.5em" color="greyMedium">
              {data.space.description}
            </Text>

            {data.space.ctaLink && (
              <Link
                color="blue"
                positionIcon="left"
                withIcon="location"
                to={data.space.ctaLink}
              >
                {data.space.cta}
              </Link>
            )}
          </Box>
        </Box>

        <Box
          width={cols}
          mb={['1em', '2.5em']}
          mt={['2em', '10em']}
          pl={[0, '1.25em']}
          order={[-1, 0]}
        >
          <ImageRounded path={data.space.images[1]} alt={data.space.title} />
        </Box>
      </Flex>
    </Container>
  )
}
