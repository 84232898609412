import * as React from 'react'

import Hero from '../components/Hub/Hero'
import Section from '../components/Hub/Section'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'
import CalendarApi from '../components/shared/CalendarApi'
import Events from '../components/shared/Events'
import Layout from '../layouts'

const BarcelonaHubPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Section />
      <Newsletter />
      <CalendarApi dataSource="hub">
        {events => <Events events={events} />}
      </CalendarApi>
    </Layout>
  )
}

export default BarcelonaHubPage
