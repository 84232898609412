import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import HeroOverlay from '../../shared/HeroOverlay'
import { Image } from '../../shared/Image'
import { Caption, Title } from '../../shared/Typography'
import { IHero } from './types'

interface IProps {
  data: IHero
}

export default function renderHeroHomepage({ data }: IProps) {
  const cols = ['100%', '50%']
  const padd = ['0', '6em']

  return (
    <HeroOverlay>
      <Container>
        <Flex
          alignItems="center"
          mb={['7.5em', '7em']}
          px={[null, '0', '6em']}
          flexWrap="wrap"
        >
          <Box width={cols} pr={padd} mb="2.5em">
            <Title color="blue" mb="1rem">
              {data.title}
            </Title>
            <Caption color="greyMedium">{data.description}</Caption>
          </Box>

          <Box width={cols} mb={['-15%', null, '-6%']}>
            <Image path={data.image} alt={data.title} />
          </Box>
        </Flex>
      </Container>
    </HeroOverlay>
  )
}
