import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { IProp } from './types'
import View from './view'

export default function queryHeroHub() {
  return (
    <StaticQuery
      query={graphql`
        query HeroHub {
          allFoundationYaml {
            edges {
              node {
                akasha_hub {
                  title
                  description
                  image
                }
              }
            }
          }
        }
      `}
      render={({ allFoundationYaml }: IProp) => {
        const data = mergeEdges(allFoundationYaml)

        if (!data) {
          return null
        }

        return <View data={data.akasha_hub} />
      }}
    />
  )
}
