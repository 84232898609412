import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { IProp } from './types'
import View from './view'

export default function querySectionHub() {
  return (
    <StaticQuery
      query={graphql`
        query SectionHub {
          allFoundationYaml {
            edges {
              node {
                akasha_hub {
                  section {
                    title
                    description
                  }
                  space {
                    title
                    description
                    cta
                    ctaLink
                    images
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allFoundationYaml }: IProp) => {
        const data = mergeEdges(allFoundationYaml)

        if (!data) {
          return null
        }

        return <View data={data.akasha_hub} />
      }}
    />
  )
}
